<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h4>
            {{ title }}
          </h4>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              @click="addForm()"
            >
              <feather-icon
                icon="PlusIcon"
              />
              <span
                class="text-nowrap"
              > Tambah</span>
            </b-button>
            <a
              class="btn btn-sm btn-primary"
              @click.prevent="exportData()"
            >
              <feather-icon
                icon="ArrowUpRightIcon"
              />
              <span class="text-nowrap"> Export</span>
            </a>
          </div>
        </b-col>
        <br><br><br>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="pageLength"
            :options="perPage"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <label>entries</label>
          
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="items"
      responsive
      :fields="fieldsTable"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="sortDirection"
      class="position-relative table-bordered table-sm table mb-1"
    >

      <template #cell(action)="data">
        <!-- <span
            v-if="isView"
            class="mr-1"
          >
            <b-link @click="viewForm(data)">
              <b-badge variant="success">
                <feather-icon icon="FileIcon" />
              </b-badge>
            </b-link>
          </span> -->
            
        <span
          v-if="isEdit"
          class="mr-1"
        >
          <center>
            <b-button
              variant="flat-success"
              size="sm"
              class="waves-effect btn-icon"
              @click="detail(data)"
            >
              <feather-icon
                icon="FolderIcon"
              />
            </b-button>
          </center>
        </span>
      </template>

      <!-- Column: Invoice Status -->
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ currentPage }} to {{ pageLength }} of {{ recordsTotal }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="recordsTotal"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <b-modal
      id="modal-center"
      ref="my-modal-form"
      centered
      title="Form"
      hide-footer
    >
      <div><baseform
        :title_form="title_form"
        :fields="fields"
        :posturl="dataurl"
        :baseroute="baseroute"
        :dataitem="dataitem"
        :ismodal="true"
        @onsubmite="submitModal"
      /></div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BLink,BBadge,BOverlay,BModal,BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import baseform from '@/views/base/BaseFormV2.vue'
import { useEventListener } from '@vueuse/core'

export default {
  components: {
    BTable,
    BCard,
    BCol,
    // BFormGroup,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BRow,
    // BLink,
    // BBadge,
    // BOverlay,
    BModal,
    baseform,
    vSelect,
  },
  props: {
    isAdd: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    baseroute: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    fieldsform: {
      type: Array,
      default() {
        return []
      },
    },
    filter: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      title_form:'',
      currentPage: 1,
      sortBy: '',
      sortDirection: true,
      recordsTotal: 0,
      perPage: ['20','40','60'],
      pageLength : 20,
      pageSize: 20,
      search: '',
      items:[],
      loading:false,
      dataitem:{},
      fieldsTable:[],
    }
  },
  watch: {
    search() {
      this.fetchData()
    },
    currentPage() {
      this.fetchData()
    },
    sortDirection() {
      this.fetchData()
    },
    pageLength() {
      this.fetchData()
    }
  },
  beforeMount(){
    let newobj=JSON.parse(JSON.stringify(this.fields))
    for (let index = 0; index < newobj.length; index++) {
      let row = newobj[index]
      if(row.type == 'select-ajax'){
        row.key = row.key+'.name'
      }
      this.fieldsTable.push(row) 
    }
    this.fieldsTable.push('action')


  },
  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading =true
      var params ={...this.filter}
      
      // set paging
      params["start"] = (this.currentPage-1) * this.pageLength 
      params["length"] = this.pageLength 
      
      // set sort
      if(this.sortBy == '')
        params["sort[created_at]"] = "desc" 
      else
        params["sort["+this.sortBy+"]"] = this.sortDirection ? 'asc' :'desc'

      // set search
      if(this.search != '')
        params["search"] = this.search

      this.$http.get(this.dataurl,{params:params})
        .then(res  => {
                // this.pageLength = res.data.data.length
                this.recordsTotal = res.data.recordsTotal
                this.items = res.data.data
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false) 

    },
    submitModal(val) {
      if(val) this.fetchData()
      this.$refs['my-modal-form'].hide()
    },
    addForm(){
      this.title_form='Tambah'
      if(this.isModal)
      this.$refs['my-modal-form'].show()
      else
      this.$router.push({ name: this.baseroute+'-create' })
      // this.dataitem ={}
    },
    editForm(data){
      this.title_form='Edit'
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.item.id} })

      // this.dataitem = data.item
      // this.$refs['my-modal-form'].show()
    },
    detail(data){
      this.title_form='Detail'
      this.$router.push({ name: this.baseroute+'-detail',params : { id: data.item.id} })

      // this.dataitem = data.item
      // this.$refs['my-modal-form'].show()
    },
    exportData(){
      this.$http.get(this.dataurl+'/export')
        .then(res  => {
                window.open(res.data.filename,'_blank').focus()
        })
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id} })
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.fetchData()

                  // this.items = data.data
                  // this.recordsTotal = data.recordsTotal
                  // this.loading =false
  
            })
          }
        })
    }
    // onFiltered() {
    //   // console.log(filteredItems)
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   // this.totalRows = filteredItems.length
    //   // this.currentPage = 1
    //   this.fetchData()
    // },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
