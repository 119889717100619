<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute" 
        :title="title"
      >
        <!-- <template #cell(is_headoffice)="data">
          <b-badge
            v-if="data.item.is_headoffice"
            variant="primary"
          >
            <feather-icon
              icon="CheckIcon"
            />
          </b-badge>
        </template> -->
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV3.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar Region',
      dataurl:'/region',
      baseroute:'region',
      fields : [
        { key: 'code', label: 'Kode', sortable: true },
        { key: 'name', label: 'Region', sortable: true },
        { key: 'address', label: 'Alamat', sortable: true },
        { key: 'leader.name', label: 'Pimpinan'},
        { key: 'pic.name', label: 'PIC Operasional'},
        // { label: 'Lokasi', type: 'map', rules:''},
        // { key: 'is_headoffice',label: 'Kantor pusat', type: 'bool', rules:''},
      ]
    }
  },
}
</script>
<style lang="">

</style>
